import { useContext } from "react";
import Accordion from "../../global/Accordion/Accordion";
import { AppContext } from "../../HOC/WithContext/AppAuthProvider";
import styles from "./FAQ.module.scss";

export default function FAQ(): JSX.Element {
  const context = useContext(AppContext);

  return (
    <div className={styles.faq}>
      <h1 className={styles.title}>FAQ</h1>
      <div className={styles.descr}>
        {context.faqData.map((faq: any, i: number) => (
          <Accordion
            title={faq.title}
            className={styles.accordion}
            children={faq.text}
            weight={faq.weight}
            key={i}
          />
        ))}
      </div>
    </div>
  );
}
