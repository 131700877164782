import { useEffect, useState } from "react";
import Subscribe from "../global/Subscribe/Subscribe";
import Footer from "./Footer";
import Header from "./Header";
import { LayoutProps } from "./Layout.props";
import styles from "./Layout.module.scss";
import { AppAuthProvider, FaqTypes, PostsType } from "../HOC/WithContext/AppAuthProvider";
import firebase from "../../../firebase";

export default function Layout({ children }: LayoutProps): JSX.Element {
  const [newsData, setNewsData] = useState<PostsType[]>([]);
  const [faqData, setFaqData] = useState<FaqTypes[]>([]);
  const getNewsData = async () => {
    await firebase
      .firestore()
      .collection("news")
      .onSnapshot((query) => {
        let fetchData: any[] = [];
        query.forEach((post) => {
          fetchData.push(post.data());
        });
        setNewsData(
          fetchData.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf())
        );
      });
  };
  const getFaqData = async () => {
    await firebase
      .firestore()
      .collection("faq")
      .onSnapshot((query) => {
        let fetchData: any[] = [];
        query.forEach((post) => {
          fetchData.push(post.data());
        });
        setFaqData(fetchData.sort((a, b) => b.weight - a.weight));
      });
  };
  useEffect(() => {
    getNewsData();
    getFaqData();
  }, []);
  return (
    <AppAuthProvider newsData={newsData} faqData={faqData}>
      <div className={styles.layout}>
        <Header />
        <Subscribe />
        <div className={styles.container}>{children}</div>
        <Footer />
      </div>
    </AppAuthProvider>
  );
}
