import { createContext, PropsWithChildren } from "react";

export type PostsType = {
  date: string;
  id: string;
  img?: string;
  text: string;
  title: string;
};
export type FaqTypes = {
  weight: number;
  title: string;
  id: string;
  text: string;
};
export default interface IAppContext {
  newsData: PostsType[] | [];
  faqData: FaqTypes[] | [];
}

export const AppContext = createContext<IAppContext>({
  newsData: [
    {
      date: "",
      id: "",
      img: "",
      text: "",
      title: "",
    },
  ],
  faqData: [
    {
      weight: 0,
      title: "",
      id: "",
      text: "",
    },
  ],
});

export const AppAuthProvider = ({
  children,
  newsData,
  faqData,
}: PropsWithChildren<IAppContext>): JSX.Element => {
  return <AppContext.Provider value={{ newsData, faqData }}>{children}</AppContext.Provider>;
};
