import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../../../images/icons/icon-arrow.svg";
import AccordionProps from "./Accordion.props";
import cn from "classnames";
import styles from "./Accordion.module.scss";

let marked = require("marked");
marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
});

export default function Accordion({
  className,
  title,
  icon,
  children,
  ...props
}: AccordionProps): JSX.Element {
  const [active, setActive] = useState<boolean>(false);
  return (
    <div
      className={cn(styles.accordion, className, {
        [styles.active]: active,
      })}
      onClick={() => setActive(!active)}
      {...props}
    >
      <div className={styles.header}>
        <span
          className={cn(styles.icon, {
            [styles.active]: active,
          })}
        >
          {icon ? { icon } : <Arrow />}
        </span>
        <h6 className={cn(styles.text, styles.title)}>{title}</h6>
      </div>
      {active && (
        <div
          className={cn(styles.text, styles.description)}
          dangerouslySetInnerHTML={{ __html: marked(children) }}
        />
      )}
    </div>
  );
}
