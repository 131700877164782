import { useEffect, useRef } from "react";
import SliderModalProps from "./SliderModal.props";
import cn from "classnames";
import styles from "./SliderModal.module.scss";
import Slider from "react-slick";
import { ReactComponent as NextSlide } from "../../../../../images/next.svg";
import { ReactComponent as PrevSlide } from "../../../../../images/prev.svg";
import close from "../../../../../images/icons/close.png";
export default function SliderModal({ imgs, isOpen, setIsOpen }: SliderModalProps) {
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (ref.current === e.target) {
        setIsOpen(false);
      }
    });
    return document.removeEventListener("click", () => {});
  }, []);
  return (
    <div
      className={cn(styles.sliderModal, {
        [styles.isOpen]: isOpen,
      })}
      ref={ref}
    >
      <span className={styles.close} onClick={() => setIsOpen(false)}>
        <img src={close} alt='...' />
      </span>
      <Slider
        dots={false}
        infinite
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        nextArrow={<NextSlide />}
        prevArrow={<PrevSlide />}
        className={styles.slider}
      >
        {imgs.map((img, i) => (
          <img src={img} alt='news_img' className={styles.img} key={i} />
        ))}
      </Slider>
    </div>
  );
}
