import { useEffect, useState } from "react";
import styles from "./BlockComponent.module.scss";
import { BlockComponentProps } from "./BlockComponent.props";
import cn from "classnames";

export default function BlockComponent({
  img,
  title,
  descr,
  dots = false,
  titleDots = false,
  virtualPage = false,
}: BlockComponentProps) {
  const [cloud, setCloud] = useState<boolean>(false);

  useEffect(() => {
    if (img.includes("cloud") || img.includes("burnout") || img.includes("postQ")) {
      setCloud(true);
    }
  }, [img]);

  return (
    <div className={styles.wrapper}>
      {title && (
        <h3
          className={cn(styles.title, {
            [styles.dots]: titleDots,
          })}
        >
          {title}
        </h3>
      )}
      <div
        className={cn(styles.img, {
          [styles.dots]: dots,
          [styles.cloudImg]: cloud,
          [styles.resetImg]: !virtualPage,
        })}
      >
        <img alt='title' src={img} />
      </div>
      <div className={styles.descr}>{descr}</div>
    </div>
  );
}
