import { useState } from "react";
import Link from "../global/Link/Link";
import styles from "./Layout.module.scss";
import logo from "../../../images/logo.png";
import SupportModal from "../global/modals/SupportModal/SupportModal";
import useMediaQuery from "../hooks/useMediaQuery";
import Burger from "../global/Burger/Burger";

import cn from "classnames";

import { ReactComponent as Arrow } from "../../../images/icons/arrow.svg";
import { ReactComponent as CES } from "../../../images/icons/ces-seeklogo.com.svg";
import { ReactComponent as SCC } from "../../../images/icons/scc.svg";
import { ReactComponent as SCC_text } from "../../../images/icons/scc_text.svg";

export const links = [
  {
    path: "/",
    name: "Features",
  },
  {
    path: "/Cryptocurrency",
    name: "Cryptocurrency",
  },
  {
    other: [
      {
        path: "/virtualOffice",
        name: "Virtual Office",
      },
      {
        path: "/connectingBlockchain",
        name: "Connecting Blockchain & Real World",
      },
      {
        path: "/handicap",
        name: "Handicap Accessible",
      },
    ],
  },
  {
    path: "/news",
    name: "News",
  },
  {
    path: "/faq",
    name: "FAQ",
  },
  {
    path: "/press",
    name: "Press Release",
  },
  { path: "#", name: "Contacts", open: true },
];
export const mobLinks = [
  {
    path: "/",
    name: "Features",
  },
  {
    path: "/Cryptocurrency",
    name: "Cryptocurrency",
  },
  {
    path: "/virtualOffice",
    name: "Virtual Office",
  },
  {
    path: "/connectingBlockchain",
    name: "Connecting Blockchain & Real World",
  },
  {
    path: "/handicap",
    name: "Handicap Accessible",
  },
  {
    path: "/news",
    name: "News",
  },
  {
    path: "/faq",
    name: "FAQ",
  },
  {
    path: "/press",
    name: "Press Release",
  },
  { path: "#", name: "Contacts", open: true },
];
function Header(): JSX.Element {
  const [openSupport, setOpenSupport] = useState<boolean>(false);
  const [openBurger, setOpenBurger] = useState<boolean>(false);
  const [hovered, setHovered] = useState<boolean>(false);
  const isTablet = useMediaQuery("(max-width:768px)");
  const renderLinks = () => {
    return links.map(({ name, path, open, other }, i) => {
      if (other) {
        return (
          <li
            className={styles.other}
            key={i}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => setHovered(!hovered)}
          >
            <span>
              Other Applications <Arrow />
            </span>
            <div
              className={cn(styles.hoverBlock, {
                [styles.hovered]: hovered,
              })}
            >
              {other.map((item, i) => (
                <Link type='router' to={item.path} key={i}>
                  {item.name}
                </Link>
              ))}
            </div>
          </li>
        );
      }
      return (
        <li key={i} onClick={() => isTablet && setOpenBurger(false)}>
          <Link type='router' to={path} onClick={open ? () => setOpenSupport(true) : undefined}>
            {name}
          </Link>
        </li>
      );
    });
  };
  const renderMobLinks = () => {
    return mobLinks.map(({ name, path, open }, i) => {
      return (
        <li key={i} onClick={() => isTablet && setOpenBurger(false)}>
          <Link type='router' to={path} onClick={open ? () => setOpenSupport(true) : undefined}>
            {name}
          </Link>
        </li>
      );
    });
  };
  return (
    <header className={styles.nav_wrapper}>
      <SupportModal isOpen={openSupport} onClose={setOpenSupport} />
      <div
        className={cn(styles.logo_wrapper, {
          [styles.tablet]: isTablet,
        })}
      >
        <div className={styles.logo}>
          <img src={logo} alt='logo' />
        </div>
        <div className={styles.CES}>
          {/* <div className={styles.content}>
            <div className={styles.icons}>
              <SCC />
              <SCC_text />
            </div>
            <p>
              <span>April 4-7, 2022 | Columbus, OH</span> | Greater Columbus Convention Center
            </p>
          </div> */}
          <ul
            className={cn(styles.nav, {
              [styles.active]: openBurger,
            })}
          >
            {isTablet ? renderMobLinks() : renderLinks()}
          </ul>
        </div>
        {isTablet && <Burger isOpen={openBurger} onClickHandler={setOpenBurger} />}
      </div>
    </header>
  );
}
export default Header;
