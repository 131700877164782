import React from "react";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import Layout from "./common/components/layout";
import ConnectingBlockchain from "./common/components/Pages/ConnectingBlockchain/ConnectingBlockchain";
import TechPage from "./common/components/Pages/TechPage/TechPage";
import Handicap from "./common/components/Pages/Handicap/Handicap";
import FAQ from "./common/components/Pages/FAQ/FAQ";
import Press from "./common/components/Pages/Press/Press";
import News from "./common/components/Pages/News/News";
import Admin from "./common/components/Pages/Admin/Admin";
import CryptoCurrency from "./common/components/Pages/CryptoCurrency/CryptoCurrency";
import VirtualOffice from "./common/components/Pages/VirtualOffice/VirtualOffice";

function App(): JSX.Element {
  return (
    <div className='App'>
      <HashRouter basename={"#"} hashType='noslash'>
        <Layout>
          <Switch>
            <Route path='/' exact component={TechPage} />
            <Route path='/cryptoCurrency' exact component={CryptoCurrency} />
            <Route path='/virtualOffice' component={VirtualOffice} />
            <Route path='/connectingBlockchain' component={ConnectingBlockchain} />
            <Route path='/handicap' component={Handicap} />
            <Route path='/faq' component={FAQ} />
            <Route path='/press' component={Press} />
            <Route path='/news' component={News} />
            <Route path='/admin111' component={Admin} />
            <Route path='*'>{<Redirect to='/' />}</Route>
          </Switch>
        </Layout>
      </HashRouter>
    </div>
  );
}

export default App;
