import { useState } from "react";
import "simplemde/src/css/simplemde.css";
import "codemirror/lib/codemirror.css";
import MarkdownEditor from "react-markdown-editor-smde";
import MarkdownProps from "./Markdown.props";

export default function Markdown({ setValue }: MarkdownProps) {
  const [ref, setRef] = useState<any>();
  const click = () => {
    if (ref) {
      setValue(ref.mdValue);
    }
  };

  return (
    <>
      <MarkdownEditor ref={(r) => setRef(r)} />
      <button type='button' style={{ color: "white" }} onClick={click}>
        Save MD text
      </button>
    </>
  );
}
