import { useState } from "react";
import Line from "../global/Line/Line";
import instagram from "../../../images/icons/inst.svg";
import facebooke from "../../../images/icons/facebook.svg";
import media from "../../../images/icons/media.svg";
import linkedin from "../../../images/icons/linkedin.svg";
import youtube from "../../../images/icons/you.svg";
import twitter from "../../../images/icons/twiter.svg";
import telegram from "../../../images/icons/telegram.svg";
import SupportModal from "../global/modals/SupportModal/SupportModal";
import useMediaQuery from "../hooks/useMediaQuery";
import styles from "./Layout.module.scss";
import Logo from "../../../images/logo_max.svg";
import El from "../../../images/el.svg";

const terms = [
  {
    name: "Privacy policy",
    url: "#",
  },
  {
    name: "Terms & conditions",
    url: "#",
  },
  {
    name: "Cookie policy",
    url: "#",
  },
  {
    name: "Refund policy",
    url: "#",
  },
  {
    name: "Acceptable use policy",
    url: "#",
  },
  {
    name: "Disclaimer",
    url: "#",
  },
];
const icons = [
  {
    icon: instagram,
    link: "https://www.instagram.com/traceless_box/",
  },
  {
    icon: linkedin,
    link: "https://www.linkedin.com/company/75043704",
  },
  {
    icon: youtube,
    link: "https://www.youtube.com/channel/UCs9OdnD49P2njJ-FNpMCrwg",
  },
  {
    icon: twitter,
    link: "https://twitter.com/Traceless_Box",
  },
  {
    icon: telegram,
    link: "https://t.me/tracelessbox",
  },
  {
    icon: facebooke,
    link: "https://www.facebook.com/tracelessbox/",
  },
  {
    icon: media,
    link: "https://medium.com/@tracelessbox",
  },
];
export default function Footer(): JSX.Element {
  const tablet1024 = useMediaQuery("(max-width:1024px)");
  const tablet768 = useMediaQuery("(max-width:768px)");
  const mobile = useMediaQuery("(max-width:425px)");
  const [openSupport, setOpenSupport] = useState<boolean>(false);
  const renderTerms = () =>
    terms.map((item, i) => (
      <a className={styles.term} href={item.url} key={i}>
        {item.name}
      </a>
    ));
  const renderIcons = () =>
    icons.map((item, i) => (
      <a href={item.link} key={i} rel='noreferrer' target='_blank'>
        <img alt='icon' src={item.icon} />
      </a>
    ));

  const renderDesctop = () => (
    <div className={styles.footerBottom}>
      <div className={styles.icons}>
        {renderIcons()}
        {tablet1024 && (
          <>
            <br /> <span>©2021 Traceless Box</span>
          </>
        )}
      </div>
      <div className={styles.terms}>{renderTerms()}</div>
    </div>
  );
  const renderTablet = () => (
    <div className={styles.tablet}>
      <div className={styles.tabletTerms}>{renderTerms()}</div>
      <div className={styles.tabletBottom}>
        <div className={styles.tabletIcons}>{renderIcons()}</div>
        <span>©2021 Traceless Box</span>
      </div>
    </div>
  );
  const renderMobile = () => (
    <div className={styles.mobile}>
      <div className={styles.icons}>{renderIcons()}</div>
      <span>©2021 Traceless Box</span>
    </div>
  );
  return (
    <footer className={styles.footer}>
      <SupportModal isOpen={openSupport} onClose={setOpenSupport} />
      <Line color='grey' height={1} />
      <div className={styles.prefooter}>
        <a href='https://maxpowers.info/' rel='noreferrer' target='_blank'>
          <img alt='max_powers' src={Logo} />
        </a>
        <p>
          <a href='https://maxpowers.info/' rel='noreferrer' target='_blank'>
            This project was developed by "Max Powers, LLC"{" "}
            <span>IT & Electronics specialists</span>
          </a>
        </p>
        <a href='https://maxpowers.info/' rel='noreferrer' target='_blank'>
          <img alt='el' src={El} />
        </a>
      </div>
      <Line color='grey' height={1} />
      {tablet768 && renderTablet()}
      {!tablet768 && renderDesctop()}
      {mobile && renderMobile()}
    </footer>
  );
}
