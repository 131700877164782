import React, { useState, memo, useEffect } from "react";
import NewsPostProps from "./News.props";
import { ReactComponent as More } from "../../../../images/icons/next.svg";
import News from "../../../../images/imgs/news.jpeg";
import styles from "./NewsPost.module.scss";
import cn from "classnames";
import Line from "../Line/Line";
import useMediaQuery from "../../hooks/useMediaQuery";
import SliderModal from "../modals/SliderModal/SliderModal";

let marked = require("marked");
marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false,
  sanitize: true,
  smartLists: true,
  smartypants: false,
});
function NewsPost({ postsData }: NewsPostProps) {
  const { title, date, img, text } = postsData;

  const [openPost, setOpenPost] = useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(false);
  const [imageArray, setImageArray] = useState<string[]>([]);
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [descrElement, setDescrElement] = useState<any>();
  const [descrHeight, setDescrHeight] = useState<number>(0);
  useEffect(() => {
    if (img) {
      const newArray: string[] = [];
      img.split(" ").forEach((url) => {
        if (url.includes("https")) {
          return newArray.push(url);
        }
        newArray.push("https://" + url);
      });
      setImageArray(newArray);
    }
  }, [img]);

  useEffect(() => {
    if (descrElement) {
      setDescrHeight((prev) =>
        prev > descrElement.offsetHeight ? prev : descrElement.offsetHeight
      );
    } else {
      setDescrHeight(20);
    }
  }, [text, descrHeight]);
  return (
    <>
      <div
        className={cn(styles.post, {
          [styles.isMore]: isMore,
        })}
        onClick={() => setOpenPost(!openPost)}
      >
        <div className={styles.postPreview}>
          <div
            className={styles.img}
            style={{
              backgroundImage: `url(${imageArray.length > 0 ? imageArray[0] : News})`,
            }}
            onClick={() => setOpenSlider(true)}
          />
          <div className={styles.subDescr}>
            <span className={styles.date}>{date}</span>
            <h4 className={styles.title}>{title}</h4>
            <Line color='white' height={2} className={styles.deviver} />
            {isMore ? (
              <div
                className={styles.descr}
                dangerouslySetInnerHTML={{ __html: marked(text) }}
                ref={(r) => setDescrElement(r)}
              />
            ) : (
              <div
                className={styles.descr}
                dangerouslySetInnerHTML={{
                  __html:
                    text.length > 100 && descrHeight < 100
                      ? marked(text.slice(0, 100).concat("..."))
                      : descrHeight >= 100
                      ? marked(text.slice(0, 20).concat("..."))
                      : marked(text),
                }}
                ref={(r) => setDescrElement(r)}
              />
            )}
          </div>
          {(text.length > 100 || descrHeight >= 100) && (
            <More
              className={cn(styles.more, {
                [styles.opened]: isMore,
              })}
              onClick={() => setIsMore(!isMore)}
            />
          )}
        </div>
      </div>

      <SliderModal
        imgs={imageArray.length > 0 ? imageArray : [News]}
        isOpen={openSlider}
        setIsOpen={setOpenSlider}
      />
    </>
  );
}
export default memo(NewsPost);
