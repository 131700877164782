import styles from "./ConnectingBlockchain.module.scss";
import Lottie from "../TechPage/lottie/Lottie";
import animation1 from "./lottie/box_1/ani1.json";
import animation1_1 from "./lottie/box_1/ani1_1.json";
import animation2 from "./lottie/box_2/ani2.json";
import animation2_2 from "./lottie/box_2/ani2_2.json";
import animation3 from "./lottie/box_3/ani3.json";
import animation3_3 from "./lottie/box_3/ani3_3.json";
import animation4 from "./lottie/box_4/ani4.json";
import animation4_4 from "./lottie/box_4/ani4_4.json";
export default function ConnectingBlockchain(): JSX.Element {
  return (
    <div className={styles.connectingBlockchain}>
      <div className={styles.controlBlock}>
        <h1 className={styles.title}>
          Traceless Box can control the lock based on Blockchain events
        </h1>
        <Lottie animation={[animation1, animation1_1]} />
        <div className={styles.description}>
          <ul className={styles.list}>
            <li>The lock can be unlocked when crypto payments is received.</li>
            <li>
              When lock returns to the locked position, deposit can be returned to the over the
              blockchain.
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.ordersBlock}>
        <h2 className={styles.title}>Rent your home</h2>
        <Lottie animation={[animation2, animation2_2]} />
        <h3 className={styles.titleOrders}>
          New opportunities are opening to the home owner when the “Box” is used to rent out their
          properties.
        </h3>
        <div className={styles.description}>
          <p>
            Home owners can use the "Box" to pay less fees on websites like AirBnB and their payment
            processing merchants.
          </p>
          <ul>
            <li>Fast, Cheap, and irreversible payments using blockchain.</li>
            <li>
              Smart gadgets, such as entrance lock, com be controlled by the tenant during the paid
              period.
            </li>
            <li>
              Everything is remote — each tenant receives a unique digital key that's sent upon the
              payment receipt. The key is activated and deactivated automatically.
            </li>
            <li>
              Check each tenant's rental history and renews written by other homeowners, veritable
              through blockchain.
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.incomeBlock}>
        <h2 className={styles.title}>Rent your stuff</h2>
        <Lottie animation={[animation3, animation3_3]} />
        <div className={styles.description}>
          <p>
            Participate in the "Shared Economy" and earn additional income. Other your guests on the
            experience they never had.
          </p>
          <ul>
            <li>
              Minibar filled with local beverages can be accessible after guests pays for it.{" "}
            </li>
            <li>Different means of transportation can be offered at an additional charge.</li>
            <li>Paid laundry or pool access is a reality now. Earn more by offering more.</li>
          </ul>
        </div>
      </div>

      <div className={styles.safeBlock}>
        <h2 className={styles.title}>Make your home smarter</h2>
        <Lottie animation={[animation4, animation4_4]} />
        <div className={styles.description}>
          <p>Traceless Box will optimize your visitor's stay and save you money.</p>
          <ul>
            <li>Increase guest satisfaction with programmable gadget controls.</li>
            <li>
              Guests are more likely to turn off-air conditioner and heater when they can turn it
              back remotely.
            </li>
            <li>Offer additional things to your visitors and earn more.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
