import BlockComponent from "../../global/BlockComponent/BlockComponent";
import styles from "./CryptoCurrency.module.scss";

import automated from "../../../../images/imgs/crypto/automated.svg";
import miner from "../../../../images/imgs/crypto/miner.svg";
import pool from "../../../../images/imgs/crypto/pool.svg";
import earnings from "../../../../images/imgs/crypto/earnnings.svg";
import stats from "../../../../images/imgs/crypto/stats.svg";
import virtual from "../../../../images/imgs/crypto/virtyal.svg";
import iot from "../../../../images/imgs/crypto/IOT.svg";
import self from "../../../../images/imgs/crypto/self.svg";
import proof from "../../../../images/imgs/crypto/proof.svg";
import node from "../../../../images/imgs/crypto/node.svg";
import defi from "../../../../images/imgs/crypto/defi.svg";
import anon from "../../../../images/imgs/crypto/anon.svg";
import wallet from "../../../../images/imgs/crypto/wallets.svg";
import transitions from "../../../../images/imgs/crypto/transitions.svg";
import vpn from "../../../../images/imgs/crypto/vpn.svg";
import remote from "../../../../images/imgs/crypto/remote.svg";
import MiniNav from "../../global/MiniNav/MiniNav";

export default function CryptoCurrency(): JSX.Element {
  return (
    <div className={styles.cryptoCurrency}>
      <div className={styles.mining} id='mining'>
        <BlockComponent
          img={automated}
          titleDots
          title='Automated Troubleshooting'
          descr='Reboot, reset, re-program - prevent the miners from staying idle.'
        />
        <BlockComponent
          img={miner}
          dots
          title='Mass Miner Setup'
          descr='Whether there is one hundred or one thousand miners, the "Box" will put them all up and runnning within minutes.'
        />
        <BlockComponent
          img={pool}
          dots
          title='Mass Pool Change'
          descr='This task is known as tedious and time consuming. Our algorithm will check the links for errors and do the work for you.'
        />
        <BlockComponent
          img={earnings}
          dots
          title='Earnings Comparison'
          descr='Some pools are "luckier" than others, find out instantly and switch on the go.'
        />
        <BlockComponent
          img={stats}
          dots
          title='Miner stats & reports'
          descr='Comprehensive statistics about each machine can help to understand their weak points.'
        />
        <BlockComponent
          img={virtual}
          dots
          title='Virtual and Sound alarms'
          descr='For those who want more than just an email notification - the "Box" can trigger visual and sound alarms wirelessly or by wire.'
        />
        <BlockComponent
          img={iot}
          dots
          title='Algorithms for IOT & basic devices'
          descr="Rotate exhaust fans faster when it's hot and control the lights with the motion detector, create your own algorithms."
        />
        <BlockComponent
          img={self}
          dots
          title='Self Hosted Mining'
          descr='Challenge the luck without paying fees to the 3rd parties - your own solo mining pool is hosted in the "Box."'
        />
      </div>
      <div className={styles.farming} id='farming'>
        <BlockComponent
          img={proof}
          dots
          title='Proof of stake'
          descr='Instead of trusting exchanges, keep "proof of stake" wallets in your own possession at all times and earn passive income.'
        />
        <BlockComponent
          img={node}
          dots
          title='Node Hosting'
          descr='Host the nodes using the hardware resources provided by the "Box" (e.g. Bitcoin lightning node) and generate passive income.'
        />
        <BlockComponent
          img={defi}
          dots
          title='DeFi projects'
          descr='Browse through the carefully picked DeFi projects and their risk assessments, invest instantly.'
        />
      </div>
      <div className={styles.everything} id='everything'>
        <BlockComponent
          img={anon}
          dots
          title='Anonymity & Security'
          descr='Highest security and anonymity levels provided by the "Box," including Post-Quantum encryption.'
        />
        <BlockComponent
          img={wallet}
          dots
          title='Multysignature wallets'
          descr='Sign transactions together with the "Box" or your partners to ensure the highest asset protection.'
        />
        <BlockComponent
          img={transitions}
          dots
          title='Timed transactions'
          descr='Create timed transaction "trees," automatically executable in parallel or in sequence.'
        />
        <BlockComponent
          img={vpn}
          dots
          title='VPN'
          descr='IPFS-based VPN connection between the app and the "Box" for the higher anonymity.'
        />
        {/* <BlockComponent
          img={remote}
          dots
          title='Remote command execution'
          descr='For the mining hotels - manage subnets and provide remote access to the 3rd parties on the go.'
        /> */}
      </div>

      <MiniNav mining={"mining"} farming={"farming"} something={"everything"} />
    </div>
  );
}
