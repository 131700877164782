import styles from "./Press.module.scss";
import { saveAs } from "file-saver";

export default function Press(): JSX.Element {
  const saveFile = () => {
    saveAs("/press.pdf", "Press Release.pdf");
  };
  return (
    <div className={styles.press}>
      <h2>Press Release</h2>
      <h1 className={styles.title}>Traceless Box</h1>
      <div className={styles.descr}>
        <p>
          There are about 8 billion devices that all together make up the Internet of Things: smart
          watches, cameras, kettles, air conditioners, and other appliances. If one’s smart home
          gets hacked by some funny guy, he might move the alarm clock by an hour, change the
          temperature settings, and put extra sugar in your morning coffee. But the real intruders
          are not limited to such harmless pranks.
        </p>
        <p>
          The vulnerability of the weakest link determines the vulnerability of the whole system and
          that’s where “Traceless Box” comes into play, as it will turn even the most vulnerable
          smart home device into impregnable fortress.
        </p>
        <p>
          Smart homes of today require the technology of tomorrow and “Traceless Box” is the
          world-changing invention, which incorporates compactness and flexibility of the Raspberry
          Pi, stability of the Ethereum blockchain, and reliability of IPFS network.
        </p>
        <ul>
          <li>
            <h4> What is it:</h4>
            <p>
              Small box that is connected to the router by wire or Wi-Fi. It stays in the home
              network and controls home appliances on-demand or according to the pre-defined
              scenarios. It is synced with Ethereum blockchain, which means that it can be
              programmed to control things based on the payment receipt. Traditional payment methods
              are included too.
            </p>
          </li>
          <li>
            <h4> Use cases:</h4>
            <span className={styles.subTitle}>Hotels, apartments, and glamping</span>
            <p>
              A hotel minibar that opens up after a regular cryptocurrency payment has been
              received; an apartment downtown or even a remote house that welcomes guests and opens
              its doors to visitors if they have made a deposit; “Traceless Box” makes it all a
              reality.
            </p>
            <span className={styles.subTitle}>Shared economy</span>
            <p>
              “Traceless Box” can be used to automate and improve rental processes. This is
              especially relevant for hard-to-reach and unmanned locations, where access to the
              purchased item needs to be provided after the payment has been accepted
            </p>
            <span className={styles.subTitle}>Pick up points and storage lockers</span>
            <p>
              Almost anything can be automated and transformed into a next level service with the
              “Traceless “Box”, including pick up points, where customers can pay in crypto and pick
              up the goods; or lockers at the public places, where users can make micro payments for
              short term rentals.
            </p>
          </li>

          <li>
            <h4> Product-market fit:</h4>
            <p>
              Target markets for this invention are growing. Industry leaders predict that the
              global sharing economy will reach <span>$335 billion</span> by 2025. The global market
              of smart home devices in 2022 will grow 44% over 2020 to reach $123 billion. By 2025,{" "}
              <span> 20% </span> of households will use Smart Devices, and the market will reach{" "}
              <span>$173 billion</span> .
            </p>
            <p>
              Our development roadmap includes creating applications for: decentralized
              communications, decentralized internet, internet service providers (to reduce some of
              their daily burdens), private cloud services, and passive income from shared internet
              connection.
            </p>
          </li>
        </ul>
      </div>
      <button className={styles.download} onClick={() => saveFile()}>
        Press Release.pdf
      </button>
    </div>
  );
}
