import { useState } from "react";
import cn from "classnames";
import { MiniNavProps } from "./MiniNav.props";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import styles from "./MiniNav.module.scss";

export default function MiniNav({ mining, farming, something }: MiniNavProps): JSX.Element {
  const [miningActive, setMiningActive] = useState<boolean>(true);
  const [farmingActive, setFarmingActive] = useState<boolean>(false);
  const [somethingActive, setSomethingActive] = useState<boolean>(false);

  const setActiveClass = (link: string) => {
    switch (link) {
      case mining:
        setMiningActive(true);
        setFarmingActive(false);
        setSomethingActive(false);
        break;
      case farming:
        setMiningActive(false);
        setFarmingActive(true);
        setSomethingActive(false);
        break;
      case something:
        setMiningActive(false);
        setFarmingActive(false);
        setSomethingActive(true);
        break;
      default:
        setMiningActive(false);
        setFarmingActive(false);
        setSomethingActive(false);
        return;
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {[
          { name: "Mining", ref: mining },
          { name: "Farming", ref: farming },
          { name: "Everything Else", ref: something },
        ].map((link, i) => {
          return (
            <Link
              to={link.ref}
              smooth={true}
              duration={500}
              onClick={() => setActiveClass(link.ref)}
              className={cn(styles.link, {
                [styles.activeM]: link.name === "Mining" && miningActive,
                [styles.activeF]: link.name === "Farming" && farmingActive,
                [styles.activeS]: link.name === "Everything Else" && somethingActive,
              })}
              key={i}
            >
              {link.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
