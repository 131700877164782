import BlockComponent from "../../global/BlockComponent/BlockComponent";
import styles from "./VirtualOffice.module.scss";

import vpn from "../../../../images/imgs/virtualOffice/vpn.svg";
import teaming from "../../../../images/imgs/virtualOffice/teaming.svg";
import burnout from "../../../../images/imgs/virtualOffice/burnout.svg";
import cloud from "../../../../images/imgs/virtualOffice/cloud.svg";
import virus from "../../../../images/imgs/virtualOffice/virus.svg";
import office from "../../../../images/imgs/virtualOffice/office.svg";
import virtual from "../../../../images/imgs/virtualOffice/virtual.svg";
import network from "../../../../images/imgs/virtualOffice/network.svg";
import postQ from "../../../../images/imgs/virtualOffice/postQ.svg";

export default function VirtualOffice(): JSX.Element {
  return (
    <div className={styles.virtualOffice}>
      <div className={styles.firstDots}>
        <BlockComponent
          img={vpn}
          virtualPage
          titleDots
          title='VPN Connection is easy'
          descr='Easy and extremely simple VPN connection capability.
          Users can connect to the office network, multiple offices can establish VPN network between each other, all in one click.'
        />
        <BlockComponent
          img={teaming}
          virtualPage
          titleDots
          title='Interactive teaming'
          descr='We deliver a long forgotten feeling of a team being together in a single office through the use of chats and voice rooms, delivered immediately upon connection to the office network.'
        />
        <BlockComponent
          img={burnout}
          virtualPage
          titleDots
          title='Burnout management'
          descr='Since employees now work in the virtual office, it is much easier to account for the time they spend working, thus making it easier to make sure they don’t overwork their hours.'
        />
      </div>

      <div className={styles.secondDots}>
        <BlockComponent
          img={cloud}
          virtualPage
          titleDots
          title='Secure corporate cloud'
          descr='Our progressive solution allows securing files with the highly progressive encryption algorithm. They can be stored on the physical drives located in the company office and shared with others by generating a link, which provides the content, but at the same time doesn’t reveal where the content is really stored.'
        />
        <BlockComponent
          img={virus}
          virtualPage
          titleDots
          title='Virus monitoring'
          descr='One of the apps launched in the box can be the network monitoring tool, it will alarm the owner if something sketchy is happening with one of the network devices, not necessarily with the “Box” itself.'
        />
        <BlockComponent
          img={office}
          virtualPage
          titleDots
          title='Remote office use'
          descr='Employees can utilize office resources while being home. IOT gadgets at work, such as printer, TV, Bluetooth lock, safe box door, air conditioner, or any other “smart” device can now be controlled from afar.'
        />
      </div>

      <div className={styles.thirdDots}>
        <BlockComponent
          img={virtual}
          virtualPage
          titleDots
          title='Virtual workstations'
          descr='This optional feature can be useful to those with limited computational resources available on their personal computers, as well as to companies trying to save on licensing requirements. Substantial financial resources can be saved by scheduling access to the Virtual Machine between specialists using the same program.'
        />
        <BlockComponent
          img={network}
          virtualPage
          titleDots
          title='Enterprise network protection.'
          descr='Whenever the client is trying to establish connection to another “Box”, it is never routed through our servers; instead, peer-to-peer connection goes through the network of nodes until it finds the destination, then two boxes handshake.'
        />
        <BlockComponent
          img={postQ}
          virtualPage
          titleDots
          title='Post quantum encryption'
          descr='Traffic and stored files can be encrypted using post-quantum algorithm. This is a protection against “future attack”, when all traffic is being saved now just to be decoded years later, when productive and cheap quantum computers become a reality. It is also a good to start acting now and be prepared for the inevitable.'
        />
      </div>
    </div>
  );
}
