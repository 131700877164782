import { useEffect, useState } from "react";
import firebase from "../../../../firebase";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import NewsPost from "../../global/NewsPost/NewsPost";
import cn from "classnames";
import styles from "./Admin.module.scss";
import Markdown from "./Markdown";
import Accordion from "../../global/Accordion/Accordion";

export default function Admin() {
  const [email, setEmail] = useState<string>("");
  const [pass, setPass] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [login, setLogin] = useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [previewDate, setPreviewDate] = useState<string>("");
  const [previewText, setPreviewText] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [onLoadDataToFB, setonLoadDataToFB] = useState<boolean>(false);
  const [rendererItems, setRendererItems] = useState<string>("news");
  const [allData, setAllData] = useState<any>([]);

  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      title: "",
      date: "",
      weight: "",
    },
  });
  const onLogin = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, pass)
      .then((res) => {
        setLogin(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(`${error.code}, message: ${error.message}`);
        setLoading(false);
      });
  };
  const loginRender = () => (
    <div className={styles.loginRender}>
      <input onChange={(e) => setEmail(e.target.value)} placeholder='email' />
      <input onChange={(e) => setPass(e.target.value)} placeholder='pass' type='password' />
      <button onClick={onLogin}>submit</button>
      {error && <span style={{ color: "red" }}> {error}</span>}
    </div>
  );
  const onSubmitNews = async (data: any) => {
    setonLoadDataToFB(true);
    const postData = {
      id: uuidv4(),
      title: data.title,
      date: data.date,
      text: previewText,
      img: imageUrl,
    };
    if (postData.title.length === 0 || postData.text.length === 0) {
      return alert("Title and description are required!");
    }
    await firebase
      .firestore()
      .collection("news")
      .doc(postData.id)
      .set(postData)
      .then((res) => {
        clearData();
        setonLoadDataToFB(false);
      })
      .catch((error) => console.error(error));
  };
  const newsRender = () => (
    <>
      <form onSubmit={handleSubmit(onSubmitNews)} className={styles.newsRender}>
        <input
          placeholder='Title'
          {...register("title")}
          onChange={(e) => setPreviewTitle(e.target.value)}
        />
        <input
          placeholder='date'
          type='date'
          {...register("date")}
          onChange={(e) => setPreviewDate(e.target.value)}
        />
        <textarea
          placeholder='separate the links with a space'
          onChange={(e) => setImageUrl(e.target.value)}
        />
        <div>
          <Markdown setValue={setPreviewText} />
        </div>
        <br />
        <button type='submit'>Send to DB</button>
      </form>
      <div>
        <NewsPost
          postsData={{
            title: previewTitle,
            // text: previewText.replace(/\n/g, "<br/>"),
            text: previewText,
            date: previewDate,
            id: "1123dsfdf32dfgdhdh",
            img: imageUrl,
          }}
        />
      </div>
    </>
  );
  const onSelector = () => (rendererItems === "news" ? newsRender() : faqRender());
  const onSubmitFaq = async (data: any) => {
    setonLoadDataToFB(true);
    const postData = {
      id: uuidv4(),
      title: data.title,
      text: previewText,
      weight: +data.weight,
    };
    if (postData.title.length === 0 || postData.text.length === 0) {
      return alert("Title and description are required!");
    }
    await firebase
      .firestore()
      .collection("faq")
      .doc(postData.id)
      .set(postData)
      .then((res) => {
        clearData();
        setonLoadDataToFB(false);
      })
      .catch((error) => console.error(error));
  };
  const faqRender = () => (
    <>
      <form onSubmit={handleSubmit(onSubmitFaq)} className={styles.faqRender}>
        <input
          placeholder='Title'
          {...register("title")}
          onChange={(e) => setPreviewTitle(e.target.value)}
        />
        <input placeholder='weight for FAQ' {...register("weight")} type='number' />
        <Markdown setValue={setPreviewText} />
        <br />
        <button type='submit'>Submit</button>
      </form>
      <div>
        <Accordion title={previewTitle} children={previewText} />
      </div>
    </>
  );
  const clearData = () => {
    setImageUrl("");
    setPreviewText("");
    setPreviewDate("");
    setPreviewTitle("");
    reset({
      title: "",
      date: "",
      weight: "",
    });
  };
  const getAllData = async (folder: string) => {
    await firebase
      .firestore()
      .collection(folder)
      .onSnapshot((query) => {
        let fetchData: any[] = [];
        query.forEach((post) => {
          fetchData.push(post.data());
        });
        setAllData(fetchData.sort((a, b) => b.weight - a.weight));
      });
  };
  const rednerAllData = () => {
    return allData.map((item: any) => {
      return (
        <ul key={item.id}>
          <li>
            Title: <span className={styles.title}>{item.title}</span>
            <br />
            ID: <span className={styles.id}>{item.id}</span>
            {item.weight && (
              <>
                <br />
                Weight: {item.weight}
              </>
            )}
            <br />
            Descriprion: {item.text.slice(0, 100).concat("...")}
          </li>
          <span className={styles.delete} onClick={() => deleteData(item.id, rendererItems)}>
            Delete
          </span>
        </ul>
      );
    });
  };
  const deleteData = (id: string, collection: string) => {
    firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .delete()
      .catch((e) => console.error(e));
  };
  useEffect(() => {
    if (rendererItems === "news") {
      getAllData("news");
    } else {
      getAllData("faq");
    }
  }, [rendererItems]);
  return (
    <div className={styles.adminWrapper}>
      <h1 className={styles.adminTitle}> ADMIN PANNEL</h1>
      {onLoadDataToFB && (
        <div
          className={cn(styles.loading, {
            [styles.loadingActive]: onLoadDataToFB,
          })}
        >
          Loading...
        </div>
      )}
      {login && (
        <div className={styles.toolbar}>
          <button
            className={cn({
              [styles.isSelected]: rendererItems === "news",
            })}
            onClick={() => setRendererItems("news")}
          >
            News
          </button>
          <button
            className={cn({
              [styles.isSelected]: rendererItems === "faq",
            })}
            onClick={() => setRendererItems("faq")}
          >
            FAQ
          </button>
        </div>
      )}
      <div className={styles.body}>
        {loading ? (
          <div style={{ margin: "100px", textAlign: "center" }}>Loading...</div>
        ) : !login ? (
          loginRender()
        ) : (
          onSelector()
        )}
      </div>
      {login && <div className={styles.allDataContainer}>{rednerAllData()}</div>}
    </div>
  );
}
